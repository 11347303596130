<template>
  <v-row class="match-height">
    <v-col>
      <Breadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <v-form
        ref="form"
        class="multi-col-validation"
        lazy-validation
      >
        <v-card>
          <v-card-title>{{ formTitulo }}</v-card-title>
          <v-row class="mx-5">
            <!-- Nombre -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                ref="refName"
                v-model="obj.name"
                label="Nombre"
                outlined
                dense
                placeholder="Nombre"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                ref="refusername"
                v-model="obj.userName"
                label="Usuario"
                outlined
                dense
                placeholder="Usuario"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                ref="refoperetionType"
                v-model="obj.operationType"
                label="Tipo de operación"
                outlined
                dense
                placeholder="Tipo de operación"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                ref="refcreatedAt"
                v-model="obj.createdAt"
                label="Fecha de la operación"
                outlined
                dense
                placeholder="Fecha de la operación"
              >
              </v-text-field>
            </v-col>
            <v-col
              v-if="false"
              cols="12"
            >
              <v-textarea
                ref="refinitialValue"
                v-model="obj.initialValue"
                label="Valor inicial"
                outlined
                dense
                placeholder="Valor inicial"
              >
              </v-textarea>
            </v-col>
            <v-col
              v-if="false"
              cols="12"
            >
              <v-textarea
                ref="refinitialValue"
                v-model="obj.finalValue"
                label="Valor final"
                outlined
                dense
                placeholder="Valor final"
              >
              </v-textarea>
            </v-col>
          </v-row>

          <v-card-actions
            v-if="true"
            class="mt-5"
          >
            <v-row>
              <!-- Botones de acción -->
              <v-col cols="12">
                <v-btn
                  type="reset"
                  outlined
                  class="mx-2"
                  @click="cancelarForm"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCalendar,
  mdiAlertOctagonOutline,
  mdiInformation,
} from '@mdi/js'
import { ref, reactive, computed } from '@vue/composition-api'
import moment from 'moment'
import { useRouter } from '@/utils'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import HTTP from '@/utils/axios/axios-config-base'

export default {
  components: {
    Breadcrumbs,
  },
  setup() {
    const imageUrlDefault = ref(require('@/assets/images/default-img.png'))
    const apiKey = 'pk.eyJ1Ijoiam9zZTg5MDgyMyIsImEiOiJja3plZjIzczMwZjEwMm9vYmdpam81b2Y4In0.4BCud0O62PlRyUwXYVaXCQ'
    const tab = ref('')
    const keypublicWorksubCategory = ref(0)
    const keyComponnet = ref(0)

    const status = ref([
      { id: 'approved', name: 'Aprobado', disabled: false },
      { id: 'pending', name: 'Pendiente', disabled: false },
      { id: 'Denied', name: 'Denegado', disabled: true },
      { id: 'canceled', name: 'Cancelado', disabled: false },
    ])
    const offLink = ref(false)
    const isEditing = ref(true)

    // tabs
    const tabs = reactive([
      {
        id: '1', title: 'Datos Generales', icons: mdiAlertOctagonOutline, error: false,
      },
      {
        id: '2', title: 'Ubicación', icons: mdiAlertOctagonOutline, error: false,
      },
      {
        id: '3', title: 'Logs del Sistema', icons: mdiAlertOctagonOutline, error: false,
      },

      // { id: '2', title: 'Requisitos / Tiempo' },
    ])
    const modalidades = [
      { id: 'Mixed', name: 'Presencial y Virtual' },
      { id: 'FaceToFace', name: 'Presencial' },
      { id: 'Virtual', name: 'Virtual' },
    ]
    const { router, route } = useRouter()
    const items = reactive(route.value.params.items ? route.value.params.items : [
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: 'Logs',
        disabled: false,
        href: '',
      },
      {
        text: 'Detalles',
        disabled: true,
        href: '',
      },
    ])
    const formTitulo = ref(route.value.params.formTitulo ? route.value.params.formTitulo : 'Detalles del log')
    const detalleLogsId = route.value.params.id ? route.value.params.id : route.value.query.id
    const keyComponent = ref(0)
    const obj = reactive({
      createAt: '',
      entityName: '',
      finalValue: '',
      initialValue: '',
      name: '',
      operationType: '',
      systemLogId: '',
      userName: '',
    })
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })
    const loadingSaveForm = ref(false)
    const geo = ref(true)
    const form = ref(null)
    const fileInput = ref(null)
    const searchForm = ref(true)
    const height = ref(500)
    const color = ref('#FFA500')
    const ubicacionLocal = ref([])
    const refreferenceAddress = ref(null)
    const refName = ref(null)
    const refDescripcion = ref(null)
    const link = ref('')
    const nameLink = ref('')
    const projection = ref('')
    const inaugurated = ref(false)
    const refPublishAt = ref(null)
    const refExpireAt = ref(null)
    const refAttentionSchedule = ref(null)

    const campos = reactive({
      name: {
        error: false,
        mensajesError: [],
      },
      description: {
        error: false,
        mensajesError: [],
      },
      nameLink: {
        error: false,
        mensajesError: [],
      },
      link: {
        error: false,
        mensajesError: [],
      },
      projection: {
        error: false,
        mensajesError: [],
      },
      inaugurated: {
        error: false,
        mensajesError: [],
      },
      publishAt: {
        error: false,
        mensajesError: [],
      },
      expireAt: {
        error: false,
        mensajesError: [],
      },
      imageUrl: {
        error: false,
        mensajesError: [],
      },
      referenceAddress: {
        error: false,
        mensajesError: [],
      },
      attentionSchedule: {
        error: false,
        mensajesError: [],
      },
      latitude: {
        error: false,
        mensajesError: [],
      },
      longitude: {
        error: false,
        mensajesError: [],
      },
    })

    const minDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    const minDatePublishAt = computed(() => moment((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)).toISOString())
    const minDateExpireAt = computed(() => moment(obj.publicWorksDate).toISOString())

    // Errores
    const permissions = localStorage.getItem('permissions')
    const deshabilitarForm = computed(() => permissions.includes('Obras:Obras:FullAccess') || permissions.includes('Obras:Obras:ApproveAccess'))

    const disableByModality = () => {
      if (obj.modality === 'FaceToFace' || !obj.modality) {
        return true
      }

      return false
    }

    const requiredByModality = v => {
      let temp = true

      if (v && (obj.modality === 'Mixed' || obj.modality === 'Virtual')) {
        temp = true
      } else if (obj.modality === 'FaceToFace') {
        temp = true
      } else {
        temp = false
      }

      return temp
    }

    const isUrl = s => {
      if (s === '') return false
      const regexp = /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/

      return regexp.test(s)
    }
    const isUrlValid = s => {
      if (s === '') return true
      const regexp = /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/

      return regexp.test(s)
    }
    const isValidName = s => {
      if (s === '') return true
      const regexp = /^[A-Za-zÀ-ÿ]+([\\ ]{0,1}[A-Za-z0-9À-ÿ]+)*$/

      return regexp.test(s)
    }
    const detalleLogsById = async id => {
      try {
        if (id) {
          const URL = `${HTTP.defaults.baseURL}/identity-api/systemLogs/${id}`
          const resp = await HTTP.get(URL)
          obj.createdAt = resp.data.value.createdAt
          obj.entityName = resp.data.value.entityName
          obj.finalValue = resp.data.value.finalValue
          obj.initialValue = resp.data.value.initialValue
          obj.name = resp.data.value.name
          obj.operationType = resp.data.value.operationType
          obj.systemLogId = resp.data.value.systemLogId
          obj.userName = resp.data.value.userName
        }
      } catch (error) {}
    }
    detalleLogsById(detalleLogsId)

    // Logs del Sistema
    const url = ref(`${HTTP.defaults.baseURL}identity-api/SystemLogs`)
    const cargando = ref(false)
    const havePagination = ref(false)
    const haveSearch = ref(false)
    const haveDetail = ref(true)

    return {
      apiKey,
      imageUrlDefault,
      detalleLogsId,
      items,
      status,
      tab,
      tabs,
      form,
      refreferenceAddress,
      refName,
      refDescripcion,
      link,
      nameLink,
      projection,
      inaugurated,
      refPublishAt,
      refExpireAt,
      refAttentionSchedule,
      offLink,
      isEditing,
      fileInput,
      formTitulo,
      menu: ref(false),
      menu2: ref(false),
      menu3: ref(false),
      loadingSaveForm,
      modalidades,
      keyComponent,
      keypublicWorksubCategory,
      obj,
      geo,
      campos,
      searchForm,
      height,
      color,
      ubicacionLocal,
      notificaciones,
      keyComponnet,
      disableByModality,
      requiredByModality,
      minDate,
      minDatePublishAt,
      minDateExpireAt,
      isValidName,
      detalleLogsById,

      // Logs del Sistema
      url,
      cargando,

      havePagination,
      haveSearch,
      haveDetail,

      // iconos
      icons: {
        mdiCalendar,
        mdiAlertOctagonOutline,
        mdiInformation,
      },
      isUrl,
      isUrlValid,
      deshabilitarForm,
      esHoraValida: v => (v.split(':')[0] >= 0 && v.split(':')[0] < 24) || 'El campo hora debe estar entre 0 y 23',
      changeDate: () => {
        refPublishAt.value.reset()
        refExpireAt.value.reset()
      },
      cancelarForm: () => {
        router.push({ name: route.value.params.cancelarForm ? route.value.params.cancelarForm : 'log_sistema' })
      },

    }
  },
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
.border {
  border: #e0e0e0 solid 1px;
  border-radius: 15px;
}

.border-tramite-activo {
  border: #185faa solid 2px;
}
.border-tramite {
  border: white solid 2px;
}
.border-tramite:hover {
  border: #185faa solid 2px;
}

.border-transiciones-activo {
  border: #4caf50 solid 2px;
}
.border-transiciones {
  border: white solid 2px;
}
.border-transiciones:hover {
  border: #4caf50 solid 2px;
}

.border-estado-activo {
  border: #fbad4b solid 2px;
}
.border-estado {
  border: white solid 2px;
}
.border-estado:hover {
  border: #fbad4b solid 2px;
}

.compact-form {
  transform: scale(0.7);
  transform-origin: left;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.truncate_name {
    max-width: 12vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.truncate {
    max-width: 30vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
